<template>
    <div class="block w-full" id="schedule">
        <div class="container sm:w-full md:w-full w-full relative">
            <div @click="slide = slide - 4" class="absolute cursor-pointer" v-if="windowWidth > 600" style="left: 7%; top: 37.5%; z-index: 4;">
                <b-icon
                    icon="arrow-left"
                    size="is-small"
                    custom-size="mdi-24px">
                </b-icon>
            </div>
            <div class="container sm:w-full md:w-4/5 w-full">
                <b-carousel-list class="w-full" v-model="slide" :data="dates" :arrow="false" :has-drag="windowWidth < 600" :items-to-list="windowWidth < 600 ? 5 : 7" :items-to-show="windowWidth < 600 ? 5 : 7">
                    <template slot="item" slot-scope="list">
                        <div class="block text-center border boder-solid rounded-md sm:m-1 md:m-3 m-1 py-3" @click="selectedDate(list.list)"  :class="list.date === now.date && list.year === now.year && list.month === now.month ? 'bg-blue-500 text-white border-blue-500' : 'border-current'">
                            <small class="block mb-0">{{ list.day }}</small>
                            <span class="font-semibold">{{ list.date }}</span>
                        </div>
                    </template>
                </b-carousel-list>
            </div>
            <div @click="slide = slide + 4" class="absolute cursor-pointer" v-if="windowWidth > 600" style="right: 7%; top: 37.5%;">
                <b-icon
                    icon="arrow-right"
                    size="is-small"
                    custom-size="mdi-24px">
                </b-icon>
            </div>
        </div>
        <div class="w-full block border border-solid border-t-0 border-r-0 border-l-0 my-2"></div>
        <div class="slot block">
            <div class="block" v-for="(slot, n) in data.Slots" :key="n">
                <span class="font-semibold">{{ slot.Title }}</span>
                <div v-if="hide" class="flex flex-wrap">
                    <div v-for="(item, index) in slot.Data" :key="index" @click="selectedTime(item)" :class="checkSelectedSchedule(item) ? 'bg-black text-white hover:bg-white hover:text-black' : 'hover:bg-gray-200'" class="border border-solid mr-2 my-2 rounded-lg cursor-pointer p-2 flex justify-between">
                        <span class="my-auto text-sm">{{ item.Time }}</span>
                    </div>
                </div>
            </div>
            <div v-if="isMobile" class="border border-solid rounded-md p-2">
                <span class="mr-2">{{ data.Item.Name }}</span>
                <span class="font-bold block">{{ selectedSchedule ? selectedSchedule.substring(0,10) + ' ' + formatDate(selectedSchedule, 'HH:mm') : '' }}</span>
                <span class="block font-bold" style="color: red;">Total {{ formatNumber(AmountSelected || 0) }}</span>
            </div>
        </div>
        <div class="w-full block mb-2 border border-solid border-t-0 border-r-0 border-l-0"></div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            slide: 0,
            currentDate: new Date(),
            now: {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                date: new Date().getDate(),
                day: this.formatDate(new Date(), 'ddd')
            },
            dates: [],
            selectedSchedule: '',
            AmountSelected: '',
            obj: {
                ...this.data.Item
            },
            hide: true,
            schedule: {},
            isMobile: window.innerWidth < 767
        }
    },
    methods: {
        init (type) {
            var temp = []
            var current = this.currentDate
            var lastDayOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0)
            temp = this.makeData(current, lastDayOfMonth)
            if (this.dates.length === 0) this.dates = temp
            else this.dates = [...this.dates, ...temp]
            current = new Date(current.getFullYear(), current.getMonth() + 1, 1)
            this.currentDate = current
            if (type === 'first') {
                this.init('second')
            }
        },
        makeData (current, lastDayOfMonth) {
            var date
            var temp = []
            for (var i = current.getDate(); i <= lastDayOfMonth.getDate(); i++) {
                date = current.getFullYear() + '-' + (parseInt(current.getMonth()) + 1) + '-' + i
                temp.push({
                    year: current.getFullYear(),
                    month: current.getMonth() + 1,
                    date: i,
                    day: this.formatDate(new Date(date), 'ddd')
                })
            }
            return temp
        },
        async selectedDate (item) {
            this.selectedSchedule = ''
            this.now = item
            var date = item.year + '-' + item.month + '-' + item.date
            date = this.formatDate(new Date(date), 'YYYY-MM-DD')
            var response = await this.$baseApi.get(this.data.get + '&Date=' + date + '&Qty=' + this.$route.query.Qty)
            this.data.Slots = response
        },
        checkSelectedSchedule (item) {
            // if (this.isMobile) {
            //     var temp = {}
            //     var element = document.getElementById('schedule')
            //     if (element && element.__vue__) temp = { ...element.__vue__.obj, Date: element.__vue__.selectedSchedule, AmountSelected: element.__vue__.AmountSelected }
            //     this.schedule = temp
            // }

            var value = false
            var date = this.formatDate(new Date(this.selectedSchedule), 'HH:mm')
            if (date.toUpperCase() === item.Time) {
                console.log('here', item)
                value = true
            }
            return value
        },
        selectedTime (item) {
            if (window.innerWidth < 767) {
                this.hide = false
                setTimeout(() => {
                    this.hide = true
                }, 10)
            }
            var date = this.now.year + '-' + this.now.month + '-' + this.now.date + ' ' + item.Time
            date = this.formatDate(new Date(date), 'YYYY-MM-DD hh:mm a')
            this.selectedSchedule = date
            this.AmountSelected = item.Price
            return date
        }
    },
    watch: {
        slide () {
            console.log(this.slide)
            console.log(this.dates.length / 2, this.dates.length)
            if (this.dates.length / 2 < this.slide + 1) this.init('second')
        }
    },
    mounted () {
        this.init('first')
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .has-shadow {
        box-shadow: inherit !important;
    }
    ::v-deep .has-icons-left {
        left: -1.5 rem !important;
    }
    ::v-deep .has-icons-right {
        right: 3.5 rem !important;
    }
</style>
